  @media screen and (min-width: 576px) { 
    .register-block-center, .register-block-end, .register-block-start {
        width: 100% !important;
      }

      .main {
        background-position: 90% 5% !important;
        background-size: contain !important;
      }

      /* .commands {
        background-position: 85% 0% !important;
        background-size: contain !important;
      } */

      .main-info p{
        max-width: 300px;
      }
  }

  @media screen and (min-width: 768px) {

    .footer li{
      font-size: 24px !important;
      width: auto !important;
    }

    .main-info p{
      max-width: 264px !important;
      font-size: 18px !important;
      background-color: #f0f8ff00 !important;
      padding: 0px !important;
    }

    .commands-info{
      padding-left: 72px;
    }

    .commands-info p{
      max-width: 300px !important;
      font-size: 18px !important;
      background-color: #f0f8ff00 !important;
    }

    .App-header {
      padding: 2rem 5% !important;
    }

    .logotype img{
      width: 224px;
    }

    .main-info h1{
        font-size: 88px !important;
        line-height: 85px !important;
    }

    .register-block-center, .register-block-end, .register-block-start {
      max-width: 100% !important;
    }
    .register-block-start{
      border-radius: 66px 0px 0px 66px !important;
    }

    .register-block-center{
        border-radius: 0px 66px 66px 0px !important;
    }

    .register-block{
      align-items: center;
    }

    .register-block img{
      max-width: initial !important;
    }
  }

  @media screen and (min-width: 992px) {

    .commands {
      background-position: calc(100% - 120px) 80% !important;
      /* background-size: 308px !important; */
    }

    .navbar{
      width: 75%;
    }

    .navbar{
      border-radius: 35px;
    }
  }

  @media screen and (min-width: 1200px) {
    .register-block-center{
        border-radius: 0px !important;
    }

    .register-block-end{
        border-radius: 0px 66px 66px 0px !important;
    }
  }
  
  @media screen and (min-width: 1440px) {
    
  }
  
  @media screen and (min-width: 1620px) {

  }
  