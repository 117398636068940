body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer li{
  list-style: none;
}

h1 {
  font-family: 'MontserratBlack';
}

h3, h2 {
  font-family: 'MontserratBold';
}

p {
  font-family: 'MontserratRegular';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: #f1dfc5ba;
}

.margin-block{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.padding-content {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.header-block h2{
  text-align: center;
  color: blue;
  font-weight: 500;
}

.header-block strong{
  color: #000;
}

.logotype img{
  width: 150px;
}

.navbar{
  border-radius: 16px;
}

.navbar-nav{
  width: 100%;
  justify-content: space-around;
  font-size: 18px;
}

.navbar-collapse {
  width: 75px;
}

.navbar>.container {
  justify-content: end !important;
}

.nav-link {
  text-align: end;
}
