.App {
  max-width: 1420px;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main {
  background-repeat: no-repeat;
  background-position: calc(100% + 48px) 80%;
  background-size: 224px;
}

.main-info h1{
  font-family: 'MontserratBlack';
  font-size: 42px;
  color: #f17878;
  line-height: 40px;
}

.main-info h3{
  font-family: 'MontserratBold';
  display: block;
    max-width: 454px;
    font-weight: bold;
}

.main-info p{
  display: none;
  max-width: 252px;
  line-height: 24px;
  font-size: 14px;
  background-color: #f1dfc5de;
  padding: 4px;
  border-radius: 10px;
}

.main-info button, .register button, .commands button{
  font-weight: 500;
    background-color: #303030;
    padding: 10px 42px;
    border-radius: 24px;
    border-color: #303030;
    color: #fff;
    margin: 10px 0px;
}

.main-info button:hover, .main-info button:active{
  background-color: #504e4e !important;
  border-color: #504e4e !important;
  color: #fff;
}

.description {
  background-color: #faf8ef;
  border-radius: 24px;
  padding: 3rem 2rem;
  box-shadow: 2px 2px 0px 1px silver;
}

.description h3{
  font-size: 18px;
  text-align: center;
  margin: 0px;
}

.shopper-block{
  background-color: #faf8ef;
    box-shadow: 0px 3px 0px 0px silver;
    border-radius: 24px;
    padding: 3rem 2rem;
    text-align: center;
    max-width: 350px;
    height: 460px;
}

.shopper-block img{
  margin-bottom: 24px;
}

.register button{
  background-color: #f17878;
  border-color: #f17878;
  width: 214px;
  font-size: 24px;
}

.register button:hover, .register button:active{
  background-color: #ee6060 !important;
  border-color: #ee6060 !important;
}

.register-block{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-block img{
  border-radius: 5px;
  max-width: 294px;
}

.register-block ol{
  display: flex;
  justify-content: center;
  font-weight: 500;
  height: 70px;
  margin-top: 2rem;
}

.register-block li{
  text-align: center;
  font-size: 18px;
}

.register-block-start{
  padding-bottom: calc(2rem + 1px) !important;
}

.register-block-center{
  padding-bottom: calc(2rem + 1px) !important;
}

.register-block-end{

}

.register-block-center, .register-block-end, .register-block-start {
  background-color: #faf8ef;
  padding: 2rem;
  box-shadow: 0px 2px 0px silver;
  display: flex;
  justify-content: center;
  border-radius: 66px;
  /* max-width: 350px; */
  width: 350px;
}

.commands {
  background-repeat: no-repeat;
  background-position: calc(100% + 176px) 80%;
  background-size: contain;
  height: 60vh;
  display: flex;
  align-items: center;
}

.commands-info{
  font-weight: bold;
}

.commands-info h1{
  color: #f17878;
}

.commands-info h3{
  font-size: 46px;
}

.commands-info p{
  display: block;
  max-width: 238px;
  line-height: 24px;
  font-size: 14px;
}

.commands button {
  background-color: #61dafb;
  border-color: #61dafb;
  color: #303030;
}

.commands button:hover, .commands button:active {
  background-color: #41d2fa;
  border-color: #41d2fa;
}

.footer {
  background-color: #2e2e2e;
  color: white;
}

.footer ul{
  display: flex;
  padding: 5%;
  font-size: 24px;
  flex-wrap: wrap;
}

.footer li{
  padding: 0 1.5rem;
  font-size: 14px;
  width: 100%;
}

.footer li a{
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  padding: 2rem 0rem 1rem;
  justify-content: space-between;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
